import React from "react";

// import "./Select.css";

function defaultGetLabel(option) {
	return option.nome;
}

function defaultGetValue(option) {
	return option.id;
}

export default function Select({
	name,
	value,
	translations,
	className,
	onChange,
	options,
	getLabel = defaultGetLabel,
	getValue = defaultGetValue,
	disabled = false,
	style = null,
}) {
	return (
		<select
			className={className}
			name={name}
			value={value}
			onChange={(e) => onChange(e.target.value)}
			disabled={disabled}
			style={style}
		>
			{/* <option value="">
				{translations.seleziona} {name}
			</option> */}
			{options.map((option, index) => (
				<option key={index} value={getValue(option)}>
					{getLabel(option)}
				</option>
			))}
		</select>
	);
}
