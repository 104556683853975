import React from "react";
import { render } from "react-dom";
import _ from 'lodash';

import { defaultConfig, defaultTheme } from './helpers/config';

import './sanitize.css';
import './base.css';

import App from "./components/App";

window.aolistino = function(config) {
    _.defaults(config, defaultConfig);
    //if (typeof config.lingua == "undefined") {
    //    config.lingua = "it";
    //}
    // if (typeof config.multiListino == "undefined") {
    //    config.multiListino= false;
    // }

    const theme = _.defaultsDeep(config.theme, defaultTheme);

    render(
        <div className="AoListino-Root">
          <App config={config} theme={theme}/>
        </div>,
        document.getElementById(config.rootEl)
    );
}
