import React from "react";
import { connect } from "react-redux";
var parse = require("html-react-parser");

import Select from "./Select";
import * as actions from "../actions/listini";
import {
	getListini,
	getPeriodiCorrenti,
	getTrattamentiCorrenti,
	getCamereCorrenti,
	getFilters,
	getCurrentPrice,
	areAllFiltersValid,
	getCurrentPrScount,
	getCurrentPrOfferta,
} from "../reducers/listini";

class List extends React.Component {
	constructor(props) {
		super(props);
		// console.log(this.props.btnlink);
	}

	render() {
		const {
			translations,
			multiListino,
			filters,
			prezzo,
			trattamentiCorrenti,
			camereCorrenti,
			listini,
			filtroListini,
			showNotes,
		} = this.props;

		if (listini.length === 0) {
			return (
				<Localizer>
					<h3>{translations.errors.nessun_listino}</h3>
				</Localizer>
			);
		}

		return (
			<div>
				{/* <div className="l__header">
					<p>{translations.seleziona_periodo}</p>
				</div> */}
				<div className="l__body">
					{multiListino == false && (
						<ul className="l__ul_listini nav nav-pills">
							{listini.map((listino) => (
								<li className="nav-item" key={listino.idListino}>
									<button
										className={`l__btn_listino nav-link ${this.props.listinoSelezionato == listino.idListino
											? "active"
											: ""
											}`}
										id={`button_${listino.idListino}`}
										onClick={() =>
											this.props.dispatch(
												actions.selectListino(listino.idListino)
											)
										}
									>
										{listino.nome}
									</button>
								</li>
							))}
						</ul>
					)}
					<div className="l__select-control">
						{multiListino && (
							<Select
								name="listino"
								className="form-control"
								value={filters.idListino}
								translations={translations}
								onChange={(value) =>
									this.props.dispatch(
										actions.changeFilterValue("idListino", value)
									)
								}
								getLabel={(option) => `${option.nome}`}
								options={listini}
							/>
						)}
						<Select
							name="periodo"
							className="form-control"
							value={filters.idPeriodo}
							translations={translations}
							onChange={(value) =>
								this.props.dispatch(
									actions.changeFilterValue("idPeriodo", value)
								)
							}
							getLabel={(option) =>
								`${option.inizio_nice} - ${option.fine_nice}`
							}
							options={this.props.periodiCorrenti}
						/>
						<Select
							name="trattamento"
							className="form-control"
							value={filters.idTrattamento}
							translations={translations}
							onChange={(value) =>
								this.props.dispatch(
									actions.changeFilterValue("idTrattamento", value)
								)
							}
							options={trattamentiCorrenti}
						/>

						<Select
							name="camera"
							className={`form-control ${camereCorrenti.length === 1 ? "d-none" : "d-block"
								}`}
							value={filters.idCamera}
							translations={translations}
							onChange={(value) =>
								this.props.dispatch(
									actions.changeFilterValue("idCamera", value)
								)
							}
							options={camereCorrenti}
							disabled={camereCorrenti.length === 1 ? true : false}
						/>
						{/* <div className="btn__div_prezzo">
							<button
								className="btn btn__prezzo"
								id="calculate"
								disabled={!this.props.enableCalculateButton}
								onClick={() => this.props.dispatch(actions.calculatePrice())}
							>
								{translations.vedi_prezzo}
							</button>
						</div> */}
					</div>
				</div>
				<div className="l__box_prezzo">
					<div className="">
						{/*<span>{translations.a_partire_da}</span>}*/}
						{prezzo != "" && prezzo != "0.00" ? (
							this.props.sconti > 0 ? (
								<div className="h1">
									<del class="text-muted">{prezzo}</del> {this.props.sconti} €
								</div>
							) : (
									<div className="h1">{prezzo} €</div>
								)
						) : (
								"-"
							)}
						{this.props.offerta != null ? (
							<a
								href={`offerte/${this.props.offerta.slug}`}
								class="h6 text-primary"
							>
								<i className="fal fa-gift"></i> {translations.offerta_attiva}
							</a>
						) : (
								""
							)}
					</div>
					{this.props.btnlink !== null ? (
						<div dangerouslySetInnerHTML={{ __html: this.props.btnlink }} />
					) : (
							""
						)}
				</div>
				{showNotes && listini.map((listino) => (
					listino.idListino === this.props.listinoSelezionato && (
						<div className="l__note_listino">{parse(listino.note_listino)}</div>
					)
				))}
			</div>
		);
	}
}

function mapStateToProps(state) {
	// console.warn(state);

	return {
		listini: getListini(state),
		listinoSelezionato: state.listini.currentListino,
		periodiCorrenti: getPeriodiCorrenti(state),
		trattamentiCorrenti: getTrattamentiCorrenti(state),
		camereCorrenti: getCamereCorrenti(state),
		filters: getFilters(state),
		prezzo: getCurrentPrice(state),
		sconti: getCurrentPrScount(state),
		offerta: getCurrentPrOfferta(state),
		enableCalculateButton: areAllFiltersValid(state),
	};
}

export default connect(mapStateToProps)(List);
