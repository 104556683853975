import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore } from "redux";

import DataLoader from "./DataLoader";
import rootReducer from "../reducers";

const store = createStore(
	rootReducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default class App extends Component {
	render() {
		const {config} = this.props;

		return (
			<Provider store={store}>
				<DataLoader
					idHotel={config.idHotel}
					theme={config.theme}
					btnlink={config.btnlink}
					lingua={config.lingua}
					multiListino={config.multiListino}
					filtroListini={config.filtroListini}
					orderByTrattamento={config.orderByTrattamento}
					showNotes={config.showNotes}
				/>
			</Provider>
		);
	}
}
