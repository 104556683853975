import * as types from "./actionTypes";

export function dataLoaded(data) {
    return {
        type: types.DATA_LOADED,
        payload: data
    };
}

export function selectListino(id) {
    return {
        type: types.SELECT_LISTINO,
        payload: id
    };
}

export function changeFilterValue(key, value) {
    return {
        type: types.CHANGE_FILTER_VALUE,
        payload: {
            key,
            value
        }
    };
}

export function calculatePrice() {
    return {
        type: types.CALCULATE_PRICE
    };
}