export const defaultConfig = {
    lingua: 'it',
    multiListino: false,
    filtroListini: '',
    showNote: false,
    theme: {},
};

export const defaultTheme = {
    colors: {
        text: '#000',
        background: '#fff',
        error: '#cc0033',
        primary: '#7ebd6d',
        secondary: '#fff',
        selectBackground: '#eee',
        btnDivBackground: '#fff',
        borderBox:'#fff'
    },
    texts: {}
};
