import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions/listini";
import List from "./List";

// TODO: aggiungere testi in altre lingue supportate
const defaultTranslations = {
	it: {
		retry: "Riprova",
		loading_error: "Errore durante il caricamento del form",
	},
	en: {
		retry: "Retry",
		loading_error: "Error during form loading",
	},
};

class DataLoader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			translations: {},
			isLoading: true,
		};
	}

	componentDidMount() {
		fetch(
			`https://hotel.adriasonline.it/eco/listino2.php?idHotel=${this.props.idHotel}&lingua=${this.props.lingua}`
		)
			// fetch(`${process.env.REACT_APP_ECO_LISTINO}?idHotel=${this.props.idHotel}&lingua=${this.props.lingua}`)
			.then((res) => res.json())
			.then((res) => {
				// console.warn(res);

				Promise.all([this.loadAsyncModules()])
					// this.fetchRemoteConfig()
					.then(() => {
						// TODO: eventualmente prevedere possibilita che le traduzioni possano essere sovrascritte dalla config remota?
						this.setState({
							isLoading: false,
						});
					})
					.catch((err) => console.error(err));
				// console.log(this.props)
				// this.props.dispatch(actions.changeFilterValue('orderByTrattamento', this.props.orderByTrattamento));

				const { filtroListini, orderByTrattamento } = this.props;

				// console.log(res.listini)

				if (filtroListini) {
					const arrayFiltroListini = filtroListini
						.split(",")
						.map((item) => parseInt(item, 10));

					let listiniFiltrati = {listini: []};
					if (orderByTrattamento) {
						listiniFiltrati.orderByTrattamento = orderByTrattamento;
					}

					res.listini.map(
						(listino) =>
							arrayFiltroListini.includes(listino.testata.idListino) &&
							listiniFiltrati.listini.push(listino)
					);
					this.props.dispatch(actions.dataLoaded(listiniFiltrati));
					this.props.dispatch(
						actions.selectListino(listiniFiltrati.listini[0].testata.idListino)
					);
				} else {
					if (orderByTrattamento) {
						res.orderByTrattamento = orderByTrattamento;
					}
					
					this.props.dispatch(actions.dataLoaded(res));
					this.props.dispatch(
						actions.selectListino(res.listini[0].testata.idListino)
					);
				}

				/*this.setState({
							isLoading: false
						});*/
			});
	}

	loadAsyncModules() {
		// FIXME: se si usa il metodo custom di inserimento tag style posso anche evitare il caricamento asincrono,
		// discorso diverso se si usa emotion in quanto includerebbe il runtime nel chunk iniziale
		return Promise.all([
			import(/* webpackChunkName: "aolistino" */ "../helpers/theme"),
			import(
        /* webpackChunkName: "i18n/[request]" */ `../i18n/${this.props.lingua}`
			),
		])
			.then(([{ injectGlobalStyle }, { translations }]) => {
				injectGlobalStyle({ ...this.props.theme });

				this.setState({
					translations,
				});

				return translations;
			})
			.catch((err) => console.error(err));
	}
	// getTranslation(key) {
	//     return defaultTranslations[this.props.config.language][key];
	// }

	render({ config, theme }, { isLoading, translations }) {
		if (isLoading) {
			return <h3 id="loading">Loading...</h3>;
		}

		// console.log(this.props)

		return (
			<List
				translations={translations}
				btnlink={this.props.btnlink}
				multiListino={this.props.multiListino}
				filtroListini={this.props.filtroListini}
				orderByTrattamento={this.props.orderByTrattamento}
				showNotes={this.props.showNotes}
			/>
		);
	}
}

export default connect()(DataLoader);
