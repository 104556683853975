import * as actions from "../actions/actionTypes";

const DEFAULT_FILTERS = {
    idListino: '',
    idPeriodo: '',
    idTrattamento: '',
    idCamera: ''
};

export const INITIAL_STATE = {
    listiniId: [],
    listiniById: {},
    trattamentiByListino: {},
    camereByListino: {},
    periodiByListino: {},
    prezziByListino: {},
    scontiByListino: {},
    offertaByListino: {},
    currentListino: null,
    lastValidFilters: null,
    // orderByTrattamento: 'ASC',
    filters: {
        ...DEFAULT_FILTERS
    }
};

function createDataObjectByListino(listini, key) {
    return listini.reduce((obj, listino) => {
        obj[listino.testata.idListino] = listino[key];

        return obj;
    }, {});
}

function createDataArray(state, key) {
    const currentItems = state.listini[key][state.listini.currentListino];

    const ids = Object.keys(currentItems);

    return ids.map(id => {
        return {
            ...currentItems[id],
            id: id
        };
    });
}

function handleDataLoaded(state, action) {
    const { listini, orderByTrattamento } = action.payload;

    state.orderByTrattamento = orderByTrattamento ? orderByTrattamento : 'ASC';

    return {
        ...state,
        listiniId: listini.map(listino => listino.testata.idListino),
        listiniById: createDataObjectByListino(listini, 'testata'),
        trattamentiByListino: createDataObjectByListino(listini, 'trattamenti_estesi'),
        camereByListino: createDataObjectByListino(listini, 'camere'),
        periodiByListino: createDataObjectByListino(listini, 'periodi'),
        prezziByListino: createDataObjectByListino(listini, 'prezzi'),
        scontiByListino: createDataObjectByListino(listini, 'sconti'),
        offertaByListino: createDataObjectByListino(listini, 'offerta'),
        // orderByTrattamento: orderByTrattamento,
        // currentListino: listini[0].testata.idListino
    };
}

function handleSelectListino(state, action) {
    const camereCorrenti = state.camereByListino[action.payload];
    const periodiCorrenti = state.periodiByListino[action.payload];
    const trattamentiCorrenti = state.trattamentiByListino[action.payload];
    // console.log('camereCorrenti',camereCorrenti)

    let idTrattamentoOrdinato;
    // let trattamentoSelezionato = '12993';

    // const {orderByTrattamento} = state;
    // console.log('orderByTrattamento',orderByTrattamento)
    // console.log('state', Object.keys(trattamentiCorrenti))

    if (typeof trattamentoSelezionato !== 'undefined' && trattamentoSelezionato !== '') {
        idTrattamentoOrdinato = trattamentoSelezionato;
    } else {
        idTrattamentoOrdinato = true ? Object.keys(trattamentiCorrenti)[0] : Object.keys(trattamentiCorrenti).slice(0).reverse()[0]    
    }

    // const idTrattamentoOrdinato = false ? Object.keys(trattamentiCorrenti)[0] : Object.keys(trattamentiCorrenti).slice(0).reverse()[0]
    // console.log(Object.keys(trattamentiCorrenti).slice(0).reverse()[0])

    let filters = {
        ...DEFAULT_FILTERS
    };

    if (Object.keys(camereCorrenti).length > 0) {
        filters = {
            ...DEFAULT_FILTERS,
            idTrattamento: idTrattamentoOrdinato,
            idPeriodo: Object.keys(periodiCorrenti)[0],
            idCamera: Object.keys(camereCorrenti)[0]
        }
    }

    return {
        ...state,
        currentListino: action.payload,
        lastValidFilters: `${filters.idTrattamento}---${filters.idCamera}---${filters.idPeriodo}`,
        // lastValidFilters: null,
        filters: filters
    };
}

function handleChangeFilterValue(state, action) {
    const newState = Object.assign({}, state);

    newState.filters = Object.assign({}, newState.filters, { [action.payload.key]: action.payload.value });
    // newState.filters[action.payload.key] = action.payload.value;
    // newState.lastValidFilters = null;
    newState.lastValidFilters = `${newState.filters.idTrattamento}---${newState.filters.idCamera}---${newState.filters.idPeriodo}`;

    return newState;
}

function handleCalculatePrice(state) {
    const { filters } = state;

    if (filters.idPeriodo === "" || filters.idCamera === "" || filters.idTrattamento === "") {
        return state;
    }

    return {
        ...state,
        lastValidFilters: `${filters.idTrattamento}---${filters.idCamera}---${filters.idPeriodo}`
    };
}

export default function listini(state = INITIAL_STATE, action) {
    // console.log(action)
    switch(action.type) {
        case actions.DATA_LOADED:
            return handleDataLoaded(state, action);
        case actions.SELECT_LISTINO:
            return handleSelectListino(state, action);
        case actions.CHANGE_FILTER_VALUE:
            return handleChangeFilterValue(state, action);
        case actions.CALCULATE_PRICE:
            return handleCalculatePrice(state);
        default:
            return state;
    }
}

export function getListini(state) {
    return state.listini.listiniId.map(id => state.listini.listiniById[id]);
}

export function getPeriodiCorrenti(state) {
    return createDataArray(state, 'periodiByListino');
}

export function getTrattamentiCorrenti(state) {
    return createDataArray(state, 'trattamentiByListino');
}

export function getCamereCorrenti(state) {
    const currentItems = state.listini.camereByListino[state.listini.currentListino];

    const ids = Object.keys(currentItems);

    return ids.map(id => {
        return {
            nome: currentItems[id],
            id: id
        };
    });
}

export function getFilters(state) {
    return state.listini.filters;
}

export function getCurrentPrice(state) {
    const { filters } = state.listini;
    // console.log('filters',filters)
    // console.log('prezziByListino', state.listini.prezziByListino)
    // console.log(state.listini)

    // if (filters.idPeriodo !== "" && filters.idCamera !== "" && filters.idTrattamento !== "") {
    if (state.listini.lastValidFilters !== null) {
        return state.listini.prezziByListino[state.listini.currentListino][filters.idTrattamento][filters.idCamera][filters.idPeriodo];
    }

    return null;
}

export function getCurrentPrScount(state) {
    const { filters } = state.listini;

    // if (filters.idPeriodo !== "" && filters.idCamera !== "" && filters.idTrattamento !== "") {
    if (state.listini.lastValidFilters !== null) {
        return state.listini.scontiByListino[state.listini.currentListino][filters.idTrattamento][filters.idCamera][filters.idPeriodo];
    }

    return null;
}

export function getCurrentPrOfferta(state) {
    const { filters } = state.listini;

    // if (filters.idPeriodo !== "" && filters.idCamera !== "" && filters.idTrattamento !== "") {
    if (state.listini.lastValidFilters !== null) {
        return state.listini.offertaByListino[state.listini.currentListino][filters.idTrattamento][filters.idCamera][filters.idPeriodo];
    }

    return null;
}
export function areAllFiltersValid(state) {
    const { filters } = state.listini;

    return (filters.idPeriodo !== "" && filters.idCamera !== "" && filters.idTrattamento !== "");
}